<template>
  <div class="article-card">
    <div
      class="article-image"
      :style="{ 'background-image' : `url( ${ media } )` }"
    >
      <span
        class="text-white"
        :class="{ featureCat: featured }"
      >
        {{ featured ? 'Featured' : 'Article' }}
      </span>
      <vsvg-icon
        v-if="!isImage"
        data="@assetsIcon/play-icon.svg"
        scale="1"
        original
        :color="`${brand.styles.primary} #ffffff`"
      />
    </div>
    <div class="article-capture">
      <h3>{{ title }}</h3>
      <div class="article-desc d-flex">
        <span
          class="img-author"
          :style="{ 'background-image' : `url( ${ authorAvatar } )` }"
        ></span>
        <span>{{ authorName }}</span>
        <span class="date-creation">{{ date }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleCard',

  props: {
    title: {
      type: String,
      required: true,
    },
    media: {
      type: String,
      required: true,
    },
    isImage: {
      type: Boolean,
      default: true,
    },
    authorAvatar: {
      type: String,
      required: true,
    },
    authorName: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    featured: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.article-card {
  overflow: hidden;
  border-radius: 3px;
  .article-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 150px;
    position: relative;
    @media (max-width: 575px) {
      height: 150px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      height: 150px;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%)
    }
    span {
      position: absolute;
      bottom: 10px;
      font-size: 12px;
      display: block;
      padding: 5px 15px;
      background-color: $feed-article-card-pill-bg-color;
      color: $feed-article-card-pill-fg-color;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .article-capture {
    height: 115px;
    background: $white-color;
    position: relative;
    h3 {
      margin-top: 0;
      margin-bottom: 15px;
      padding-top: 12.5px;
      padding-left: 12.5px;
      padding-right: 12.5px;
      color: $primary-grey-color;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 12.5px;
      padding-right: 12.5px;
    }
    .article-desc {
      height: 41px;
      line-height: 41px;
      position: absolute;
      bottom: 5px;
      width: 100%;
      span {
        display: inline-block;
        font-size: 12px;
        margin-left: 10px;
        color: $primary-grey-color;
        font-weight: bold;
      }
      .date-creation {
        position: absolute;
        right: 12.5px;
      }
      .img-author {
        width: 38px;
        height: 38px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: inline-block;
        border-radius: 50%;
        margin-left: 12.5px;
      }
    }
  }
}
</style>
